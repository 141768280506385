exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-books-js": () => import("./../../../src/pages/books.js" /* webpackChunkName: "component---src-pages-books-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-privacy-js": () => import("./../../../src/pages/privacy.js" /* webpackChunkName: "component---src-pages-privacy-js" */),
  "component---src-pages-submissions-js": () => import("./../../../src/pages/submissions.js" /* webpackChunkName: "component---src-pages-submissions-js" */),
  "component---src-pages-team-js": () => import("./../../../src/pages/team.js" /* webpackChunkName: "component---src-pages-team-js" */),
  "component---src-pages-terms-and-conditions-js": () => import("./../../../src/pages/terms-and-conditions.js" /* webpackChunkName: "component---src-pages-terms-and-conditions-js" */),
  "component---src-pages-trade-information-js": () => import("./../../../src/pages/trade-information.js" /* webpackChunkName: "component---src-pages-trade-information-js" */),
  "component---src-templates-allauthors-js": () => import("./../../../src/templates/allauthors.js" /* webpackChunkName: "component---src-templates-allauthors-js" */),
  "component---src-templates-allbooks-js": () => import("./../../../src/templates/allbooks.js" /* webpackChunkName: "component---src-templates-allbooks-js" */),
  "component---src-templates-allnews-js": () => import("./../../../src/templates/allnews.js" /* webpackChunkName: "component---src-templates-allnews-js" */),
  "component---src-templates-author-js": () => import("./../../../src/templates/author.js" /* webpackChunkName: "component---src-templates-author-js" */),
  "component---src-templates-book-js": () => import("./../../../src/templates/book.js" /* webpackChunkName: "component---src-templates-book-js" */),
  "component---src-templates-news-post-js": () => import("./../../../src/templates/news-post.js" /* webpackChunkName: "component---src-templates-news-post-js" */)
}

